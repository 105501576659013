import React, { useState } from 'react';

import { Content } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Seo from 'components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

const ClinicRegistration = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<>
			<Seo
				title='Register your clinic on BookPhysio.com'
				description={`Grow your clinic by registering with Australia's only dedicated physiotherapy booking site - BookPhysio.com. Register for free today.`}
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<Content isOpen={isMenuOpen} />
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

export default ClinicRegistration;
